<script>
/* COMPOSANTS */
/* MIXINS */
/* SERVICES */

export default {
  components: {},
  props: {},
  data() {
    return {
        messages: [],
    }
  },
  computed: {},
  mounted() {},
  
  methods: {
    error(content, reset = true, closable = true) {
       if (reset) this.resetMessages();
       this.messages.push({closable: closable, severity: "error", content: content});
       this.scrollToTop();
    },
    success(content, reset = true, closable = true) {
        if (reset)  this.resetMessages();
        this.messages.push({closable: closable, severity: "success", content: content });
    },
    resetMessages() {
        this.messages = [];
    },
  },
}
</script>

<template>
    <Message v-for="msg of messages" :key="msg.content" :closable="msg.closable" :severity="msg.severity" @close="closeMessage(msg)">
      {{ msg.content }}
    </Message>
</template>